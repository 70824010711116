<template>
    <div id="chatbot-widget" class="fixed bottom-0 right-0 z-50">
        <div
            id="chatbot-icon"
            @click="handleChatIconClick"
            class="fixed bottom-5 right-5 bg-purple-berry text-white w-16 h-16 rounded-full flex justify-center items-center cursor-pointer text-2xl shadow-2xl overflow-hidden"
        >
            <i
                class="fas fa-comments"
                :class="{ 'hide-icon': isChatOpen, 'show-icon': !isChatOpen }"
                data-gtm="chatbot-open"
            ></i>
            <i
                class="fas fa-chevron-down"
                :class="{ 'hide-icon': !isChatOpen, 'show-icon': isChatOpen }"
                data-gtm="chatbot-close"
            ></i>
        </div>
        <div
            v-if="showCallout"
            @click="dismissCallout"
            class="callout fixed bottom-20 right-16 bg-white text-purple-berry px-4 py-2 rounded-bl-lg rounded-t-lg shadow-md cursor-pointer"
            :class="{ 'fade-out': isCalloutFading }"
        >
            {{ $t('chatbot.askOurAssistant') }}
        </div>
        <div
            id="chatbot-container"
            :class="{ hidden: !isChatOpen }"
            class="fixed top-0 sm:top-auto left-0 sm:left-auto right-0 bottom-0 sm:bottom-24 sm:right-5 sm:w-96 sm:max-h-[70%] bg-white sm:rounded-2xl sm:shadow-xl flex flex-col overflow-y-auto"
        >
            <div
                class="chatbot-header sticky sm:relative bg-midnight-blue text-white p-4 sm:rounded-t-2xl h-16 flex justify-between items-center"
            >
                <div class="flex items-center justify-center w-full">
                    <h2 class="text-lg font-serif font-bold">
                        {{ $t('chatbot.askOurAssistant') }}
                    </h2>
                </div>
                <button @click="toggleChat" class="text-2xl text-gray-300 hover:text-white" data-gtm="chatbot-close">
                    &times;
                </button>
            </div>

            <div
                id="chat-messages"
                ref="chatMessages"
                class="flex-grow overflow-y-auto p-4 pb-12 text-sm"
            >
                <div
                    v-for="(message, index) in messages"
                    :key="index"
                    :class="['mb-2', message.type === 'user' ? 'text-right' : 'text-left']"
                >
                    <div
                        :class="message.type === 'user' ? 'flex flex-row-reverse' : 'flex flex-row'"
                    >
                        <div class="flex" v-if="message.type === 'user'">
                            <div
                                class="w-6 h-6 ml-3 rounded-full bg-purple-berry flex justify-center items-center text-white text-xs font-extrabold"
                            >
                                U
                            </div>
                        </div>
                        <div class="flex" v-else>
                            <div
                                class="w-6 h-6 mr-3 rounded-full bg-minty-green flex justify-center items-center text-white text-xs font-extrabold"
                            >
                                AI
                            </div>
                        </div>
                        <div class="flex flex-col mt-3">
                            <div
                                v-html="renderMarkdown(message.text)"
                                :class="
                                    message.type === 'user'
                                        ? 'text-white bg-midnight-blue rounded-b-xl rounded-tr-none rounded-tl-xl p-3 ml-8'
                                        : 'bg-gray-100 rounded-b-xl rounded-tl-none rounded-tr-xl p-3 mr-8 markdown-content'
                                "
                            ></div>
                            <div class="text-xs text-gray-500 mt-2">
                                {{ formatTimestamp(message.timestamp) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isLoading" class="mb-2 text-left flex justify-start">
                    <div
                        class="w-6 h-6 mr-3 rounded-full bg-minty-green flex justify-center items-center text-white text-xs font-extrabold"
                    >
                        AI
                    </div>
                    <div class="thinking-animation mt-3">
                        <span></span><span></span><span></span>
                    </div>
                </div>
            </div>

            <div
                class="chat-input-container sticky shadow-2xl sm:relative flex items-center p-2 sm:p-4 bg-gray-100 rounded-b-2xl"
            >
                <form @submit.prevent="sendMessage" class="flex w-full" data-gtm="chatbot-submit">
                    <input
                        v-model="userInput"
                        type="text"
                        class="flex-grow px-4 py-2 border border-gray-300 rounded-full text-base bg-white focus:outline-none focus:ring-2 focus:ring-purple-berry"
                        :placeholder="$t('chatbot.typeYourMessage')"
                    />
                    <button
                        type="submit"
                        class="ml-2 px-4 py-2 bg-purple-berry text-white rounded-full text-sm hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-berry"
                    >
                        <i class="fas fa-paper-plane"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick, computed, watch } from 'vue';
import { marked } from 'marked';
import { useWindowSize } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import DOMPurify from 'isomorphic-dompurify';
import type { MessageRequestPayload, Message } from '~/types/chatbot';

const { t } = useI18n();
const route = useRoute();
const { markets } = useAppConfig();

const messages = ref<Message[]>([]);
const userInput = ref('');
const isLoading = ref(false);
const isChatOpen = ref(false);
const chatMessages = ref<HTMLElement | null>(null);
const { width } = useWindowSize();
const showCallout = ref(false);
const isCalloutFading = ref(false);
const chatClosedByUser = ref(false);

const isSmOrLarger = computed(() => width.value >= 640);

const toggleChat = () => {
    isChatOpen.value = !isChatOpen.value;
    if (!isChatOpen.value) {
        chatClosedByUser.value = true;
        localStorage.setItem('chatClosedByUser', 'true');
    }
    if (isChatOpen.value) {
        showCallout.value = false;
    }
};

const handleChatIconClick = () => {
    toggleChat();
    dismissCallout();
};

const dismissCallout = () => {
    if (showCallout.value) {
        isCalloutFading.value = true;
        setTimeout(() => {
            showCallout.value = false;
            isCalloutFading.value = false;
        }, 500);
        chatClosedByUser.value = true;
        localStorage.setItem('chatClosedByUser', 'true');
    }
};

const renderMarkdown = (text: string) => {
    return DOMPurify.sanitize(marked.parse(text, { headerIds: false, mangle: false }));
};

function formatTimestamp(timestamp: number): string {
    return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
}

const scrollToBottom = () => {
    nextTick(() => {
        if (chatMessages.value) {
            chatMessages.value.scrollTop = chatMessages.value.scrollHeight;
        }
    });
};

const sendMessage = async () => {
    if (userInput.value.trim() === '') return;

    messages.value.push({ type: 'user', text: userInput.value, timestamp: Date.now() });
    scrollToBottom();

    const currentUserInput = userInput.value;
    userInput.value = '';
    isLoading.value = true;

    try {
        const market = route.query.market as Market | undefined;

        const body: MessageRequestPayload = {
            message: currentUserInput,
            market,
        };

        const response = await $fetch('/api/chatbot', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            credentials: 'same-origin',
        });

        messages.value.push(response);
    } catch (error) {
        console.error('Error sending message to chatbot:', error);

        messages.value.push({
            type: 'ai',
            text: 'Sorry, I encountered an error. Please try again.',
            timestamp: Date.now(),
        });
    } finally {
        isLoading.value = false;
        scrollToBottom();
    }
};

onMounted(async () => {
    // Check if the chat was previously closed by the user
    chatClosedByUser.value = localStorage.getItem('chatClosedByUser') === 'true';

    // Auto-open chat for sm and larger screens after a short delay, only if not previously closed
    if (isSmOrLarger.value && !chatClosedByUser.value) {
        setTimeout(() => {
            isChatOpen.value = true;
        }, 1000);
    } else if (!isSmOrLarger.value && !chatClosedByUser.value) {
        // Show callout for smaller screens after a short delay, only if not previously closed
        setTimeout(() => {
            showCallout.value = true;
        }, 1000);
    }

    const oldMessages = await $fetch('/api/chatbot');

    messages.value = [
        {
            type: 'ai',
            text: t('chatbot.welcomeMessage'),
            timestamp: Date.now(),
        },
        ...oldMessages,
    ];
});

// Watch for changes in screen size
watch(isSmOrLarger, (newValue) => {
    if (newValue) {
        showCallout.value = false;
    } else if (!isChatOpen.value && !chatClosedByUser.value) {
        showCallout.value = true;
    }
});
</script>

<style>
/* TODO: Do we need this? If so, we can try to import it "properly" (perhaps in chatbot v2).
 * I think there are better ways to just add the icons we need instead of using this 18KB
 * from a CDN we don't use elsewhere.
 */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');

#chatbot-icon {
    transition: transform 0.3s ease-in-out;
}

#chatbot-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition:
        opacity 0.15s ease-in-out,
        transform 0.3s ease-in-out;
}

#chatbot-icon i.hide-icon {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
}

#chatbot-icon i.show-icon {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

#chatbot-icon .fa-chevron-down {
    transform: translate(-50%, -50%) rotate(0deg);
    transition:
        opacity 0.15s ease-in-out,
        transform 0.3s ease-in-out;
}

#chatbot-icon .fa-chevron-down.show-icon {
    transform: translate(-50%, -50%) rotate(180deg);
}

.callout {
    transition: opacity 0.5s ease-out;
}

.callout.fade-out {
    opacity: 0;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
}
.markdown-content h1 {
    font-size: 1.5em;
}
.markdown-content h2 {
    font-size: 1.3em;
}
.markdown-content h3 {
    font-size: 1.1em;
}
.markdown-content p {
    margin-bottom: 0.5em;
}
.markdown-content a {
    color: #7850d4;
    text-decoration: underline;
}
.markdown-content ul,
.markdown-content ol {
    padding-left: 1.5em;
    margin-bottom: 0.5em;
}
.markdown-content li {
    margin-bottom: 0.25em;
}
.markdown-content code {
    background-color: #f0f0f0;
    padding: 0.1em 0.3em;
    border-radius: 3px;
    font-family: monospace;
}
.markdown-content pre {
    background-color: #f0f0f0;
    padding: 0.5em;
    border-radius: 3px;
    overflow-x: auto;
    margin-bottom: 0.5em;
}
.markdown-content pre code {
    background-color: transparent;
    padding: 0;
}
.markdown-content blockquote {
    border-left: 3px solid #ccc;
    padding-left: 1em;
    margin-left: 0;
    margin-right: 0;
}

.thinking-animation {
    display: flex;
}

.thinking-animation span {
    height: 8px;
    width: 8px;
    background-color: #999;
    border-radius: 50%;
    display: inline-block;
    margin: 0 2px;
    animation: thinking 1s infinite ease-in-out;
}

.thinking-animation span:nth-child(2) {
    animation-delay: 0.2s;
}

.thinking-animation span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes thinking {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-3px);
    }
}
</style>
